import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <div className="container">
                  <header className="major">
                    <h2>Conquistamos o seu interesse?</h2>
                  </header>
                  <p>
                    Entre em contacto para saber mais e obter uma demonstração.<br/>
                    <b>info@thepipetracker.com</b>
                  </p>
                </div>
                <ul className="copyright">
                    <li>&copy; ThePipeTracker</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                </ul>
            </section>
        )
    }
}

export default Footer
