import React from 'react'
import logo from '../assets/images/thepipetracker.png'

class Header extends React.Component {
    render() {
        return (
          <section>
              <section id="three" className="main style special" style={{'minHeight': '80px', 'height': '16vh', 'paddingTop': '5vh'}}>
                  <div className="grid-wrapper" >
                      <div className="col-12">
                          <span><img src={logo} alt="ThePipeTracker" style={{maxWidth:'350px', width:'50%'}}/></span>
                      </div>
                  </div>
              </section>
            <section id="header">
                <div className="inner">
                    <h1>
                        <b>Tracking</b> de Encomendas <br/>
                        numa Linha de <b>Produção</b>
                    </h1>
                    <p>
                        A sua empresa tem vários clientes e oferece diferentes tipos de serviços? <br/>
                        Os seus clientes costumam contactar a empresa de forma a saber o estado das suas encomendas? <br/>
                        Quer saber mais sobre os seus métodos de produção e melhor a sua efeciência e organização? <br/>
                        <b>Então está no sítio certo!</b><br/>
                    </p>
                </div>
            </section>
          </section>
        )
    }
}

export default Header
